var Inputmask = require('inputmask');
$(document).ready(function () {

    let elems = document.querySelectorAll(".nav-item.dropdown");
    let directEl = document.querySelectorAll('.dropdown-menu');
    [].forEach.call(elems, function (el) {
        // console.log(directEl);
        el.addEventListener("click", function (e) {
            [].forEach.call(directEl, function (DirEl) {
                // console.log(DirEl);
                DirEl.classList.remove("show");
            });
        }, false);
    });

    // raz1.onmouseover = function() {
    //     this.style.background = 'red';
    // }
    //
    // raz1.onmouseout = function(e) {
    //     this.style.background = 'green';
    //     this.children[0].style.background = (e.relatedTarget == this.children[0] ? 'yellow' : '');
    // }
});

function clearForm(myFormElement) {

    var elements = myFormElement.elements;

    myFormElement.reset();

    for (i = 0; i < elements.length; i++) {

        field_type = elements[i].type.toLowerCase();

        switch (field_type) {

            case "text":
            case "password":
            case "textarea":
                // case "hidden":

                elements[i].value = "";
                break;

            case "radio":
            case "checkbox":
                if (elements[i].checked) {
                    elements[i].checked = false;
                }
                break;

            case "select-one":
            case "select-multi":
                elements[i].selectedIndex = -1;
                break;

            default:
                break;
        }
    }
}

$(document).ready(function () {
    var selector = document.getElementById("phone-form");

    var im = new Inputmask("+7-999-999-99-99");
    im.mask(selector);

    var button = document.getElementById('sendCallback');
    document.getElementById('phone-form').addEventListener("click", function (e) {
        document.getElementById('phone-form').classList.remove("is-invalid");
        document.getElementById('phone-form-help').classList += ' hidden';
    }, false);

    button.addEventListener("click", function (e) {
        ym(57124696, 'reachGoal', 'sendform');
        // console.log('asd');
        if (!document.getElementById('phone-form').value) {
            document.getElementById('phone-form').classList += ' is-invalid';
            document.getElementById('phone-form-help').classList.remove("hidden");

            return;
        }
        $.ajax('/callback', {
            method: 'POST',
            data: {
                'phone': document.getElementById('phone-form').value,
                'email': document.getElementById('email-form').value,
                'message': document.getElementById('message-form').value,
                'token': document.getElementById('token-form').value,
            }
        })
            .then(
                function success(name) {
                    if (name === 'success') {
                        // console.log(name);
                        $('#mainCallBack').modal('hide');
                        clearForm(document.getElementById('modalform'));
                    }

                    if (name === 'fail') {
                        // console.log(name);
                        $('#mainCallBack').modal('hide');
                        clearForm(document.getElementById('modalform'));
                    }
                },

                function fail(data, status) {
                    console.log('Request failed.  Returned status of ' + status);
                }
            );
    }, false);
});